import { formatNumberToScore } from 'services/helpers'
import theme from 'theme/designTokens'
import buildTable from '../../shared/Table'
import { utilsPDF } from '../../shared/utils'

const { addAsteristic } = utilsPDF

const textStyles = {
  alignment: 'center'
}

function formatScore(score, minimumGrade) {
  const formattedScore = score ? formatNumberToScore(score) : '0,00'

  if (!score || score < minimumGrade) return addAsteristic(formattedScore)

  return formattedScore
}

function createNoteStyles(score, minimumGrade) {
  return {
    ...textStyles,
    color: !score || score < minimumGrade ? theme.palette.red.light : null
  }
}

export default function buildTableNotes(classInfo, students, subjects) {
  const disciplineColumns = []
  const workloadColumns = []
  const columnWidths = [10, '*']

  subjects.forEach(({ name, workload }, index) => {
    const disciplineColumn = {
      label: name,
      rowLabelFormatter: (_, item) =>
        formatScore(
          item.gradeDetails[index].grade.finalGrade,
          classInfo.minimumGrade
        ),
      columnStyle: textStyles,
      rowStyle: (_, item) =>
        createNoteStyles(
          item.gradeDetails[index].grade.finalGrade,
          classInfo.minimumGrade
        )
    }

    const workloadColumn = {
      label: `${workload}h`,
      columnStyle: textStyles
    }

    disciplineColumns.push(disciplineColumn)
    workloadColumns.push(workloadColumn)
    columnWidths.push('*')
  })

  return buildTable({
    data: students,
    columnStyle: {
      widths: columnWidths
    },
    columns: [
      [
        {
          label: 'Nº',
          rowLabelFormatter: (_, item, index) => index + 1,
          columnStyle: {
            rowSpan: 2
          }
        },
        {
          label: 'Nome',
          rowLabelKey: 'student.name',
          columnStyle: {
            rowSpan: 2
          }
        },
        ...disciplineColumns
      ],
      workloadColumns
    ]
  })
}
