const { UNITS } = require('services/constants')

function getCourseWorkload(unit, teoric, intern, pratical) {
  const isLikeNovoTec = [UNITS.NOVOTEC.name, UNITS.MS.name].includes(unit)

  if (isLikeNovoTec) return teoric

  if ((intern && pratical) || intern) return teoric + intern

  if (pratical) return teoric + pratical

  return teoric
}

function calcCourseFrequencyPercent(totalAbscences, classTime, courseWorkload) {
  const minutesInOneHour = 60
  const totalClassTimeWithAbscence = totalAbscences * classTime
  const frequency =
    100 -
    ((totalClassTimeWithAbscence / minutesInOneHour) * 100) / courseWorkload
  const formattedFrequency = frequency.toFixed(2).replace('.', ',')

  return `${formattedFrequency}%`
}

export { getCourseWorkload, calcCourseFrequencyPercent }
