import { Grid } from '@mui/material'
import { Button, InputQuill } from 'components/atoms'
import { getUserFromLocalStorage } from 'services/helpers'
import * as Styled from './style'

const ModalEmail = ({ handleClose, open, email }) => {
  function hiddeContractCode(userId, html) {
    const user = getUserFromLocalStorage()

    if (user.id !== userId) {
      const regexCaptureCode = /(?<=<b[^>]*?>)\d+(?=<\/b>)/g
      return html.replace(regexCaptureCode, '****')
    }

    return html
  }

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Styled.Grid>
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Styled.IconText>
              <Styled.Title variant="h5">Visualizar E-mail</Styled.Title>
            </Styled.IconText>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Styled.TextField
              label="Destinatário"
              name="cardTitle"
              fullWidth
              multiline
              disabled
              value={email.to}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Styled.TextField
              label="Assunto"
              fullWidth
              id="subject"
              value={email.subject}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <InputQuill
              values={hiddeContractCode(email.user_id, email.html)}
              label="Mensagem"
              disabled
              hideToolbar
            />
          </Grid>
        </Grid>

        <Styled.Footer>
          <Button variant="outlined" onclick={handleClose}>
            Cancelar
          </Button>
        </Styled.Footer>
      </Styled.Grid>
    </Styled.Modal>
  )
}

export default ModalEmail
