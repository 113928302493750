/* eslint-disable react/no-array-index-key */
import { useSearchParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useState } from 'react'
import { MATOGROSSO_UNIT, NOVOTEC_UNIT } from 'services/constants/units'
import { cardTitles } from './constants'
import { Card } from './Card'
import * as Styled from './style'
import { ModalCards } from './ModalCards'
import { ModalCoverPage } from './ModalCoverPage'
import { useClassDetailsContext } from '../../../context'

const ModalDocuments = ({
  handleClose,
  open,
  idDisc,
  openModalExportFilesInline
}) => {
  const [searchParams] = useSearchParams()
  const [openModalCards, setOpenModalCards] = useState(false)
  const [openModalCoverPage, setOpenModalCoverPage] = useState(false)
  const { classDetails } = useClassDetailsContext()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const isMS = [MATOGROSSO_UNIT].includes(classDetails?.unit)
  const isNovotecOrMS = [NOVOTEC_UNIT, MATOGROSSO_UNIT].includes(
    classDetails?.unit
  )
  return (
    <>
      <Styled.Modal open={open} onClose={handleClose}>
        <Styled.Grid>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12}>
              <Styled.Title variant="h5" component="h2">
                Documentos
                <Styled.Close onClick={() => handleClose(true)} />
              </Styled.Title>
            </Grid>

            {cardTitles({
              id,
              idStep: 4,
              idDisc,
              openModalExportFilesInline,
              setOpenModalCards,
              setOpenModalCoverPage,
              isMS
            })?.map((item, index) => {
              if (item.isMS || (isNovotecOrMS && item.hidden)) return null
              return (
                <Card
                  className={item.className}
                  key={index}
                  item={item}
                  handleClose={handleClose}
                />
              )
            })}
          </Grid>
        </Styled.Grid>
      </Styled.Modal>

      <ModalCards
        setOpenModalCards={setOpenModalCards}
        openModalCards={openModalCards}
      />

      <ModalCoverPage
        idDisc={idDisc}
        setOpenModalCoverPage={setOpenModalCoverPage}
        openModalCoverPage={openModalCoverPage}
      />
    </>
  )
}

export default ModalDocuments
