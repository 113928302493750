import { formatDateTimePtBr, getUserFromLocalStorage } from 'services/helpers'
import buildFooterDefault from '../../shared/BasePage/Footer'

export default function buildFooter(page, count) {
  const user = getUserFromLocalStorage()
  const footer = buildFooterDefault(page, count)

  const currentDateTime = formatDateTimePtBr(new Date())

  footer.table.body[0].unshift({
    text: `${user.name} - ${currentDateTime}`,
    alignment: 'left'
  })

  return footer
}
