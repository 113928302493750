const { capitalizeWords } = require('services/helpers')

const withTwoSignatures = (signatures, withSignatureImage) => [
  [
    {},
    withSignatureImage && signatures[1].image
      ? { image: 'signature2', width: 100, alignment: 'center' }
      : {},
    {},
    withSignatureImage && signatures[0].image
      ? { image: 'signature1', width: 100, alignment: 'center' }
      : {},
    {}
  ],
  [
    {},
    {
      stack: [
        {
          text: signatures[1].name,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[1].role,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[1].identifier,
          margin: [0, 0, 0, 5]
        }
      ],
      alignment: 'center',
      border: [false, true, false, false]
    },
    {},
    {
      stack: [
        {
          text: signatures[0].name,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[0].role,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[0].identifier,
          margin: [0, 0, 0, 5]
        }
      ],
      alignment: 'center',
      border: [false, true, false, false]
    },
    {}
  ]
]

const withOneSignature = (signatures = [], withSignatureImage) => [
  [
    {},
    {},
    withSignatureImage && signatures[0].image
      ? {
          image: 'signature1',
          width: 120,
          alignment: 'center'
        }
      : {},
    {},
    {}
  ],
  [
    {},
    {},
    {
      stack: [
        {
          text: signatures[0].name,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[0].role,
          margin: [0, 0, 0, 5]
        },
        {
          text: signatures[0].identifier,
          margin: [0, 0, 0, 5]
        }
      ],
      alignment: 'center',
      border: [false, true, false, false]
    },
    {},
    {}
  ]
]

const handleSignatures = (signatures = [], withSignatureImage) => {
  const { length } = signatures

  const lengthSignaturesMap = {
    1: () => withOneSignature(signatures, withSignatureImage),
    2: () => withTwoSignatures(signatures, withSignatureImage)
  }

  return lengthSignaturesMap[length]?.() ?? []
}

const buildSignature = (
  signatures = [],
  locale = 'São Paulo',
  withSignatureImage = true
) => {
  if (!signatures?.length) return []
  const signaturesData = handleSignatures(signatures, withSignatureImage)
  return [
    {
      id: 'signature',
      table: {
        body: [
          [
            {
              text: `${capitalizeWords(locale)}, ${new Date().toLocaleString(
                'pt-BR',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }
              )}`,
              colSpan: 5,
              alignment: 'center'
            },
            {},
            {},
            {},
            {}
          ],
          ...signaturesData
        ],
        widths: ['*', '*', '*', '*', '*'],
        heights: [30, 'auto', 50]
      },
      layout: {
        defaultBorder: false,
        hLineWidth: () => 0.1,
        vLineWidth: () => 0.1
      },
      marginTop: 20
    }
  ]
}

export default buildSignature
