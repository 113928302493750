/* eslint-disable no-undef */
/* eslint-disable camelcase */
import {
  IsEmpty,
  formatDateMonthYear,
  isEmpty,
  parseDateStringToISODate
} from 'services/helpers'

const disabledButton = (values) => {
  if (values?.active) return false

  return (
    IsEmpty(values.level) ||
    !values.code ||
    !values.discipline ||
    !values.revision_start_date ||
    !values.revision_end_date
  )
}

const findJoySubjects = (joy, data) => data?.find((i) => i.value === joy)

const parseStringValuesToSelect = (data) =>
  (data?.split(',') || []).reduce(
    (accumulator, cur) => [...accumulator, { label: cur, value: cur }],
    []
  )

const listCoursesLabels = (data) => data && data?.map((item) => item.value)

const parserGetSubject = (data, disciplineJoy) => ({
  workload_hour: data.workload_hour,
  workload_minute: data.workload_minute,
  discipline: data.discipline,
  code: data.code,
  ...(data.modality_id && { modality_id: data.modality_id }),
  active: data.active,
  level: parseStringValuesToSelect(data?.level) ?? [],
  courses: data?.courses ? parseStringValuesToSelect(data?.courses) : [],
  joy_course_api_key:
    findJoySubjects(data.joy_course_api_key, disciplineJoy) ?? '',
  revision_start_date: parseDateStringToISODate(data.revision_start_date) ?? '',
  revision_end_date: parseDateStringToISODate(data.revision_end_date) ?? ''
})

const parserPutSubject = (data, isEdit) => ({
  ...(isEdit && { active: data.active }),
  workload_hour: data.workload_hour,
  workload_minute: data.workload_minute,
  discipline: data.discipline,
  code: data.code,
  ...(data.modality_id && { modality_id: data.modality_id }),
  levels: listCoursesLabels(data?.level),
  ...(!isEmpty(data?.courses) && { courses: listCoursesLabels(data?.courses) }),
  joy_course_api_key: data.joy_course_api_key?.value || '',
  revision_start_date: formatDateMonthYear(data.revision_start_date) ?? '',
  revision_end_date: formatDateMonthYear(data.revision_end_date) ?? ''
})

export {
  disabledButton,
  findJoySubjects,
  parseStringValuesToSelect,
  parserGetSubject,
  parserPutSubject
}
