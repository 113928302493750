import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { handleFinalGrade, handlePartialGrade, handleGrade, addAsteristic } =
  utilsPDF

const getResult = ({ approved, active, occurrenceTypeName }) => {
  if (!active) {
    return occurrenceTypeName
  }

  if (approved) {
    return 'Aprovado'
  }
  return 'Reprovado'
}

export function buildTableBody({ students, classInfo, disciplineId }) {
  const noGradeView = '-'

  return students.map(({ student, frequency, gradeResume }, index) => {
    const assessmentGrade = handleGrade({
      grade: gradeResume.assessmentGrade,
      noGradeView
    })
    const activitiesGrade = handleGrade({
      grade: gradeResume.activitiesGrade,
      noGradeView
    })
    const partialGrade = handlePartialGrade({
      partialGrade: gradeResume.partialGrade,
      minimumGrade: classInfo.minimumGrade,
      noGradeView
    })
    const recoveryGrade = handleGrade({
      grade: gradeResume.recoveryGrade,
      noGradeView
    })
    const reclassificationGrade = handleGrade({
      grade: gradeResume.reclassificationGrade,
      noGradeView
    })
    const finalGrade = handleFinalGrade({
      finalGrade: gradeResume.finalGrade,
      hasAcademicPerformance: student.has_academic_performance,
      noGradeView,
      minimumGrade: classInfo.minimumGrade
    })
    const studentGradesInOrder = [
      assessmentGrade,
      activitiesGrade,
      partialGrade.grade,
      recoveryGrade,
      reclassificationGrade,
      finalGrade
    ]
    const studentName = student.name?.toUpperCase()

    return [
      { text: index + 1, style: ['tableBodyCell'] },
      {
        text:
          student.linked && student.origin_discipline !== disciplineId
            ? addAsteristic(studentName)
            : studentName,
        style: ['tableBodyCell']
      },
      {
        text: frequency.absence,
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: `${Math.floor(frequency.absenceInPercentage)}%`,
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      ...studentGradesInOrder.map((studentGrade) => ({
        text: studentGrade,
        style: ['tableBodyCell'],
        alignment: 'center'
      })),
      {
        text: getResult({
          active: student.active,
          approved: student.approved,
          occurrenceTypeName: student.occurrence_type_name
        }),
        style: ['tableBodyCell'],
        alignment: 'center'
      }
    ]
  })
}
