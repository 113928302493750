import { UNITS } from 'services/constants'
import { formatDateYearMonthLiteral } from 'services/helpers'
import buildGrid from '../../shared/Grid'

export default function buildClassInfo(courseInfo, classInfo) {
  const classYear = formatDateYearMonthLiteral(classInfo?.classStartDate)

  const gridSettings = [
    [
      {
        colSpan: 3,
        stack: [
          {
            text: 'Curso',
            bold: true
          },
          {
            text: courseInfo.course
          }
        ]
      },
      {
        colSpan: 2,
        stack: [
          {
            text: 'Unidade/Polo',
            bold: true
          },
          {
            text:
              UNITS.TRAILS_OF_THE_FUTURE.name === classInfo.unit
                ? `${classInfo.unit}/${classInfo.pole}`
                : classInfo.unit
          }
        ]
      },
      {
        colSpan: 1,
        stack: [
          {
            text: 'Grupo',
            bold: true
          },
          {
            text: classYear
          }
        ]
      }
    ],
    [
      {
        colSpan: 5,
        stack: [
          {
            text: 'Turma',
            bold: true
          },
          {
            text: classInfo.classroom
          }
        ]
      },
      {
        colSpan: 1,
        stack: [
          {
            text: 'Turno',
            bold: true
          },
          {
            text: classInfo.shift
          }
        ]
      }
    ]
  ]

  return buildGrid(gridSettings)
}
