import { DOCUMENT_TITLE_INDIVIDUAL_FORM } from '../constants'

export default function buildPrimaryHeader(unit) {
  return {
    stack: [
      {
        text: unit,
        bold: true,
        fontSize: 14,
        lineHeight: 1.2,
        alignment: 'center'
      },
      {
        text: DOCUMENT_TITLE_INDIVIDUAL_FORM,
        bold: true,
        lineHeight: 1.2,
        fontSize: 12,
        alignment: 'center',
        margin: [0, 5, 0, 0]
      }
    ]
  }
}
