import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { padding } from 'polished'

const { noBorderOnTop, borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export function buildTableHeader() {
  return {
    table: {
      widths: [45, 65, 108, '*', '*'],
      body: [
        [
          {
            text: 'Conteúdos',
            style: 'tableHeaderCell',
            colSpan: 5,
            alignment: 'center',
            fillColor: '#E0E0E0',
            border: [true, false, true, true],
            margin: [0, 5, 0, 5]
          },
          {},
          {},
          {},
          {}
        ],
        [
          {
            text: 'Aula',
            style: 'tableHeaderCell',
            alignment: 'center',
            margin: [0, 5, 0, 5]
          },
          {
            text: 'Data',
            style: 'tableHeaderCell',
            alignment: 'left',

            margin: [16, 5, 0, 5]
          },
          {
            text: 'Docente responsável',
            style: 'tableHeaderCell',
            alignment: 'left',

            margin: [16, 5, 0, 5]
          },
          {
            text: 'Conteúdo realizado',
            style: 'tableHeaderCell',
            alignment: 'left',
            border: [true, false, false, true],
            margin: [16, 5, 0, 5]
          },
          {
            text: '',
            style: 'tableHeaderCell',
            alignment: 'center',
            border: [false, false, true, true],
            margin: [0, 5, 0, 5]
          }
        ]
      ]
    },
    layout: {
      ...borderLayoutDefault,
      ...paddingLayoutDefault,

      paddingRight: (i, node) => {
        if (i === 2) {
          return 9
        }
        if (i === 0) {
          return 6
        }

        return 5
      }
    }
  }
}
