import { formatDate } from 'services/helpers'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

const buildTableBody = ({ lessons }) => {
  const body = [
    ...lessons.map((lesson, index) => [
      {
        text: (index + 1).toString().padStart(3, '0'),
        style: ['tableBodyCell'],
        alignment: 'center',
        border: [true, true, true, true]
      },
      {
        text: formatDate(lesson.lessonDate),
        style: ['tableBodyCell'],
        alignment: 'left',
        border: [true, true, true, true],
        margin: [15, 0, 0, 0]
      },
      {
        text: lesson.lessonProfessorName,
        style: ['tableBodyCell'],
        alignment: 'left',
        border: [true, true, true, true],
        margin: [15, 0, 0, 0]
      },
      {
        text: lesson.lessonDescription,
        style: ['tableBodyCell'],
        alignment: 'left',
        border: [true, true, true, true],
        margin: [15, 0, 0, 0]
      }
    ])
  ]

  return {
    table: {
      widths: [46, 67, 112, '*'],
      body
    },
    layout: {
      ...borderLayoutDefault,
      ...paddingLayoutDefault,
      paddingRight: (i, node) => {
        if (i === 0) {
          return 5
        }
        if (i === 1) {
          return 3
        }

        return 5
      }
    }
  }
}

export default buildTableBody
