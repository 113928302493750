import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'

const EMAIL = 'email'
const SMS = 'sms'
const WHATSAPP = 'whatsapp'
const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const nameAccordion = 'secretary_students_interaction'

const intialState = {
  searchType: EMAIL,
  beginAt: '',
  endAt: ''
}

const MESSAGE = {
  NOT_SEARCH:
    '<b>Encontre as interações do aluno.</b><br /> Selecione um critério e realize uma busca para encontrar as interações com o aluno.',
  NOT_FOUND:
    'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
}

const searchByOptions = [
  { value: EMAIL, label: 'E-mails enviados' },
  { value: SMS, label: 'SMS enviados' },
  { value: WHATSAPP, label: 'WhatsApp recebidos' }
]

const breadCrumbsItems = ({ register, name }) => [
  {
    path: '',
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Alunos'
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER}?${convertToParams({
      register,
      name
    })}`,
    label: name
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_STUDENTS_INTERACTIONS}?${convertToParams({
      register,
      name
    })}`,
    label: '<b>Interações</b>'
  }
]

export {
  intialState,
  nameAccordion,
  breadCrumbsItems,
  MESSAGE,
  EMAIL,
  SMS,
  WHATSAPP,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  searchByOptions
}
