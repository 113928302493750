import humps from 'humps'
import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams, deserialize } from 'services/helpers'

export const linkClassInDiscilple = async ({ register, payload }) => {
  const response = await httpPost(
    ENDPOINT.ADMIN_SECRETARY_STUDENT.DISCIPLINES_LINK_STUDENT(register),
    deserialize(payload)
  )
  return response
}

export const excludeClassLinkOfDiscilple = async (linkedId) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DISCIPLINES_LINKED}/${linkedId}`
  )
  return response
}

export const getListDisciplinesByFilter = async (params) => {
  const response = await httpGet(
    `${
      ENDPOINT.ADMIN_SECRETARY_STUDENT.DISCIPLINES_LINK_SUBJECT
    }?${convertToParams(params)}`
  )
  return response
}

export const getListCoursesClassesByStudent = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DISCIPLINES_CLASS}/${register}`
  )
  return response
}

export const getStudentsList = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST}?${convertToParams(params)}`
  )
  return response
}

export const getStudentsDeclaration = async (register, limit, page) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_DECLARATION}/${register}/declaration?limit=${limit}&page=${page}`
  )
  return response
}

export const getListModel = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_DOCUMENT}/${register}`
  )
  return response
}

export const getListCoursesClasses = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST}/${register}/school-class/courses`
  )
  return response
}

export const getStudentInfo = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/${register}`
  )
  return response
}

export const getStudentInfoHistory = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.STUDENT}/${register}/historic`
  )
  return response
}

export const getAcademicReportSPPDF = async ({
  register,
  academicReportId
}) => {
  const { STUDENT, ACADEMIC_REPORT } = ENDPOINT
  const response = await httpGet(
    `${STUDENT}/${register}/${ACADEMIC_REPORT}/sp/pdf`,
    {
      params: { academic_report_id: academicReportId }
    }
  )
  return humps.camelizeKeys(response)
}

export const getAcademicReportMGPDF = async ({
  register,
  academicReportId
}) => {
  const { STUDENT, ACADEMIC_REPORT } = ENDPOINT
  const response = await httpGet(
    `${STUDENT}/${register}/${ACADEMIC_REPORT}/mg/pdf`,
    {
      params: { academic_report_id: academicReportId }
    }
  )
  return humps.camelizeKeys(response)
}

export const getAcademicReportMSPDF = async ({
  register,
  academicReportId
}) => {
  const { STUDENT, ACADEMIC_REPORT } = ENDPOINT
  const response = await httpGet(
    `${STUDENT}/${register}/${ACADEMIC_REPORT}/ms/pdf`,
    {
      params: { academic_report_id: academicReportId }
    }
  )
  return humps.camelizeKeys(response)
}

export const getAcademicReportPRPDF = async ({
  register,
  academicReportId
}) => {
  const { STUDENT, ACADEMIC_REPORT } = ENDPOINT
  const response = await httpGet(
    `${STUDENT}/${register}/${ACADEMIC_REPORT}/pr/pdf`,
    {
      params: { academic_report_id: academicReportId }
    }
  )
  return humps.camelizeKeys(response)
}

export const deleleDocumentHistory = async (register) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.STUDENT}/historic/${register}`
  )
  return response
}

export const getStudentData = async (contract) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.CRUD}/${contract}`
  )
  return response
}

export const getListCourses = async (params) => {
  const response = await httpGet(ENDPOINT.ADMIN_SECRETARY_STUDENT.CRUD, {
    params
  })
  return response
}

export const getListSchool = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_SCHOOL}?${convertToParams(params)}`
  )
  return response
}

export const getStudentDocuments = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_DOCUMENTS}/${params}`
  )
  return response
}

export const getStudentDataByRegisterId = async (registerId) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/${registerId}`
  )

  return response
}

export const getResponsibleFinancial = async (registerId) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/${registerId}/financial-responsible`
  )

  return response
}

export const viewPdfDeclaration = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DOCUMENT_DECLARATION}/${params}/pdf`
  )
  return response
}

export const getStudentDocumentsTypes = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.SELECT_DOCUMENTS}&register=${params}`
  )
  return response
}

export const getStudentIncomeTax = async ({ register, year }) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_INCOME_TAX}/${register}/income-tax?year=${year}`
  )
  return response
}

export const getTypesDisabilities = async () => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DISABILITIES_TYPES} `
  )

  return response
}

export const getListModules = async () => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_MODULES}`
  )
  return response
}

export const uploadStudentPhoto = async (register, file) => {
  const response = await httpPut(
    ENDPOINT.ADMIN_SECRETARY_STUDENT.PHOTO_UPLOAD,
    {
      student_register: register,
      file
    }
  )
  return response
}

export const resetStudentPassword = async (register) => {
  const response = await httpPut(
    ENDPOINT.ADMIN_SECRETARY_STUDENT.RESET_PASSWORD.replace(
      ':register',
      register
    )
  )
  return response
}

export const editStudent = async (register, payload) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.CRUD}/${register}`,
    {
      ...payload
    }
  )
  return response
}

export const updateRegistrationData = async (payload, id) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/${id}`,
    payload
  )

  return response
}

export const updateResponsible = async (payload, id) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/${id}/financial-responsible`,
    payload
  )

  return response
}

export const sendPendingBillingEmail = async (billingId) => {
  const response = await httpPost(
    ENDPOINT.ADMIN_SECRETARY_STUDENT.SEND_PENDING_BILLING_EMAIL,
    {
      pending_billing_ids: [billingId]
    }
  )
  return response
}

export const sendSms = async (payload) => {
  const response = await httpPost(`${ENDPOINT.ADMIN_SECRETARY_STUDENT.SMS}`, {
    ...payload
  })
  return response
}

export const sendStudentEmail = async (payload) => {
  const response = await httpPost(`${ENDPOINT.ADMIN_SECRETARY_STUDENT.EMAIL}`, {
    ...payload
  })
  return response
}

export const createContract = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.CONTRACT}`,
    {
      ...payload
    }
  )
  return response
}

export const newDeclaration = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.CREATE_DECLARATION}`,
    {
      ...payload
    }
  )
  return response
}

export const newCourse = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.STUDENT_INFO}/course`,
    {
      ...payload
    }
  )
  return response
}

export const deleteDeclaration = async (params) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DOCUMENT_DECLARATION}/${params}`
  )
  return response
}

export const deleteDoc = async (params) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.DELETE_DOCUMENT}/${params}`
  )
  return response
}

export const updateSituation = async (statusIcon, documentId, payload) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.UPDATE_SITUATION}/${documentId}/${statusIcon} `,
    {
      ...payload
    }
  )

  return response
}
export const getDisciplinesOfCourseStudent = async ({ register, params }) => {
  const response = await httpGet(
    `${
      ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_DISCIPLINE_BY_COURSE_STUDENT
    }/${register}?${convertToParams(params)}`
  )

  return response
}

export const getActivities = async (registerId) => {
  const response = await httpGet(
    `${ENDPOINT.COMPLEMENTARY_ACTIVITY}/student/${registerId}`
  )

  return response
}

export const requestUtilization = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.REQUEST_UTILIZATION}`,
    payload
  )

  return response
}

export const getTypeActivitiesBySchoolClassId = async (schoolClassId) => {
  const response = await httpGet(
    `${ENDPOINT.COMPLEMENTARY_ACTIVITY_TYPES}/${schoolClassId}`
  )

  return response
}

export const createActivities = async (payload) => {
  const response = await httpPost(
    ENDPOINT.COMPLEMENTARY_ACTIVITY_CREATE,
    payload
  )

  return response
}
export const uploadStudentDocuments = async (params, payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_DOCUMENTS}/${params}/upload`,
    { ...payload }
  )

  return response
}

export const getListJustifyUseOfStudies = async () => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_JUSTIFY_USE_OF_STUDIES} `
  )

  return response
}

export const rateActivity = async (id, payload) => {
  const isMock = false

  if (isMock) {
    return Promise.resolve({})
  }

  const response = await httpPut(
    `${ENDPOINT.COMPLEMENTARY_ACTIVITY_RATE}/${id}`,
    payload
  )

  return response
}

export const deleteCourseById = async (id) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.CRUD}/${id}`
  )
  return response
}

export const getListStudentEmailLogs = async ({ register, params }) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_EMAIL_LOGS(
      register
    )}?${convertToParams(params)}`
  )

  return response
}

export const getListStudentSmsLogs = async ({ register, params }) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_SMS_LOGS(
      register
    )}?${convertToParams(params)}`
  )

  return response
}

export const getListStudentWhatsAppLogs = async ({ register, params }) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_WHATSAPP_LOGS(
      register
    )}?${convertToParams(params)}`
  )

  return response
}

export const getJustifyAbsence = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASS.STUDENTS}/${
      ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_JUSTIFY_ABSENCE
    }?${convertToParams(params)}`
  )
  return response
}
export const postJustifyAbsence = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.SCHOOL_CLASS.STUDENTS}/${ENDPOINT.ADMIN_SECRETARY_STUDENT.POST_JUSTIFY_ABSENCE}`,
    {
      ...payload
    }
  )
  return response
}

export const getCourseStudent = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.COURSES_STUDENT}/${register}/historic`
  )
  return response
}

export const deleteJustifyAbsence = async (studentId) => {
  const response = await httpDelete(
    `${ENDPOINT.SCHOOL_CLASS.STUDENTS}/${studentId}/${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_JUSTIFY_ABSENCE}`
  )
  return response
}

export const createHistoryStudent = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.STUDENT}/historic`,
    payload
  )
  return response
}

export const getLoadclassStudent = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.LIST_CLASS}/historic/${id}`
  )
  return response
}

export const SavedHistoryStudent = async (classIds, historicId) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.STUDENT}/historic/${historicId}/class-link`,
    classIds
  )
  return response
}

export const getListExpedition = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.BASE_URL_EXPEDITION}?register=${register}`
  )
  return response
}

export const getDetailsExpedition = async (diplomaId) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.BASE_URL_EXPEDITION}/${diplomaId}`
  )
  return response
}

export const getCourseStudentExpedition = async (register) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.COURSES_STUDENT}/${register}/expedition`
  )
  return response
}

export const postExpedition = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.BASE_URL_STUDENT_EXPEDITION}`,
    payload
  )
  return response
}

export const putExpedition = async (payload, diplomaId) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.BASE_URL_STUDENT_EXPEDITION}/${diplomaId}`,
    payload
  )
  return response
}

export const deleteExpedition = async (diplomaId) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_STUDENT.BASE_URL_EXPEDITION}/${diplomaId}`
  )
  return response
}

export const getIndividualFormPDF = async (params) => {
  const response = await httpGet(`${ENDPOINT.STUDENT}/individual-record/pdf`, {
    params
  })
  return response
}

export const getNotesMapPDF = async (params) => {
  const response = await httpGet(`${ENDPOINT.STUDENT}/class-grade-map/pdf`, {
    params
  })
  return response
}
