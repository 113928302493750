import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { addAsteristic } = utilsPDF

const buildTableBody = (students, disciplineId) =>
  students.map(({ student, frequency, gradeResume, grades }, index) => {
    const studentName = student.name?.toUpperCase()

    const studentGradesInOrder = [
      grades.B1_AVC_DOCENTE?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B1_AVA_INTEGRADA?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B2_AVC_DOCENTE?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B2_AVA_INTEGRADA?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B3_AVC_DOCENTE?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B3_AVA_INTEGRADA?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B4_AVC_DOCENTE?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      grades.B4_AVA_INTEGRADA?.reduce(
        (acc, current) => (acc || 0) + current.grade,
        null
      ),
      gradeResume.recoveryGrade || null,
      gradeResume.reclassificationGrade || null,
      gradeResume.finalExamGrade || null,
      gradeResume.finalGrade || null
    ]
    return [
      { text: index + 1, style: ['tableBodyCell'] },
      {
        text:
          student.linked && student.origin_discipline !== disciplineId
            ? addAsteristic(studentName)
            : studentName,
        style: ['tableBodyCell']
      },
      {
        text: frequency.absence,
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: `${Math.floor(frequency.absenceInPercentage)}%`,
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      ...studentGradesInOrder.map((studentGrade) => ({
        text: studentGrade?.toFixed(2).replace('.', ',') ?? '-',
        style: ['tableBodyCell'],
        alignment: 'center'
      }))
    ]
  })

export default buildTableBody
