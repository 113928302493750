import { createContext, useState, useContext } from 'react'
import { convertDateToUTC } from 'services/helpers'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { getUnifyDiscounts } from '../services'
import { dataByDateDesc } from '../components/Content/helpers'
import { type } from '../constants'

const blockOnlinePayment = (
  date,
  isUnified = false,
  hasNegociation = false
) => {
  const dateToBlock = convertDateToUTC(date)
  dateToBlock.setDate(dateToBlock.getDate() + (isUnified ? 0 : 20))
  const today = convertDateToUTC(new Date())
  return today > dateToBlock || hasNegociation
}

const initialState = {
  form: {
    situation: 'pending',
    type: '',
    reason: '',
    switch: false
  },
  blockOnlinePayment,
  loadUnifyDiscounts: () => {},
  unifyDiscounts: {}
}

export const FormContext = createContext(initialState)
export const FinancialBreakdownProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState.form)
  const [unifyDiscounts, setUnifyDiscounts] = useState({})
  const [data, setData] = useState([])

  const { setLoading } = useLoading()

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  const loadUnifyDiscounts = async () => {
    setLoading(true)
    const unifyDiscountsData = await getUnifyDiscounts({
      onError: () => setLoading(false)
    })

    if (unifyDiscountsData) {
      setUnifyDiscounts(unifyDiscountsData ?? {})
      setLoading(false)
    }
  }

  const initialStateSituation =
    formValues?.situation !== type.situation.pending
      ? formValues?.situation
      : type.situation.pending

  const [situation, setSituation] = useState(initialStateSituation)

  const dataTable =
    dataByDateDesc(data)?.filter(({ status }) => status === situation) || []

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues,
        blockOnlinePayment,
        loadUnifyDiscounts,
        unifyDiscounts,
        dataTable,
        setData,
        situation,
        setSituation
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useFinancialBreakdown = () => useContext(FormContext)
