import { formatNumberToScore } from 'services/helpers'
import theme from 'theme/designTokens'
import buildTable from '../../shared/Table'

const textStyles = {
  alignment: 'center'
}

function formatScore(score) {
  const formattedScore = score ? formatNumberToScore(score) : '0,00'
  return formattedScore
}

const payload = [
  {
    student: {
      name: 'MARIANA VIEIRA DA SILVA',
      register: 123452
    },
    grade: {
      finalGrade: 10
    }
  },
  {
    student: {
      name: 'NATHALIA MORAES COSTA',
      register: 123456
    },
    grade: {
      finalGrade: 8
    }
  },
  {
    student: {
      name: 'JOÃO CARLOS DA SILVA',
      register: 123436
    },
    grade: {
      finalGrade: 8
    }
  }
]

export default function buildTableRanking(studentsRanking) {
  const bestScoresTable = buildTable({
    data: studentsRanking.bestStudents,
    columns: [
      [
        {
          label: [
            'RANKING DE ALUNOS DA TURMA\n',
            {
              text: '(O aluno pode figurar nas duas listas, dependendo da variação de notas)',
              bold: false
            }
          ],
          columnStyle: {
            colSpan: 10,
            ...textStyles
          }
        }
      ],
      [
        {
          label: '13 MELHORES MÉDIAS DE NOTAS',
          columnStyle: {
            colSpan: 10,
            fillColor: theme.palette.green.light,
            ...textStyles
          }
        }
      ],
      [
        {
          label: 'Matrícula',
          rowLabelKey: 'enrollment',
          columnStyle: textStyles,
          rowStyle: textStyles
        },
        {
          label: 'Aluno',
          rowLabelKey: 'name',
          columnStyle: {
            colSpan: 8
          }
        },
        {
          label: 'Média',
          rowLabelKey: 'average',
          rowLabelFormatter: formatScore,
          columnStyle: textStyles,
          rowStyle: textStyles
        }
      ]
    ]
  })

  const worstScoresTable = buildTable({
    data: studentsRanking.worstStudents,
    columns: [
      [
        {
          label: '7 PIORES MÉDIAS DE NOTAS',
          columnStyle: {
            colSpan: 10,
            fillColor: '#F88078',
            ...textStyles
          }
        }
      ],
      [
        {
          label: 'Matrícula',
          rowLabelKey: 'enrollment',
          columnStyle: textStyles,
          rowStyle: textStyles
        },
        {
          label: 'Aluno',
          rowLabelKey: 'name',
          columnStyle: {
            colSpan: 8
          }
        },
        {
          label: 'Média',
          rowLabelKey: 'average',
          rowLabelFormatter: formatScore,
          columnStyle: textStyles,
          rowStyle: textStyles
        }
      ]
    ]
  })

  return [bestScoresTable, worstScoresTable]
}
