import { useSearchParams } from 'react-router-dom'
import ModalDocuments from './ModalDocuments'
import ModalClassSchedule from './ModalClassSchedule'
import { ModalExportFiles } from './ModalExportFiles'
import { ModalAtaNovoTec } from './ModalAtaNovoTec'
import { ModalAcademicResultsReport } from './ModalAcademicResultsReport'

const Modals = ({
  openModalExportFiles,
  setOpenModalExportFiles,
  openModalAtaNovoTec,
  setOpenModalAtaNovoTec,
  openModalDocuments,
  idDisc,
  setOpenModalDocuments,
  setOpenModalClassSchedule,
  openModalExportFilesInline,
  openModalClassSchedule,
  setSnack,
  typeModal,
  setLoadingOpen,
  listSchedules,
  fields,
  listSelectListDisciplines,
  setFields,
  staticFormValues
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  return (
    <>
      <ModalExportFiles
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        open={openModalExportFiles}
        setOpenModalGradeMap={setOpenModalExportFiles}
        handleClose={() => setOpenModalExportFiles(false)}
        fields={fields}
        typeModal={typeModal}
        setFields={setFields}
      />

      <ModalAtaNovoTec
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        open={openModalAtaNovoTec}
        handleClose={() => setOpenModalAtaNovoTec(false)}
        staticFormValues={staticFormValues}
        id={id}
      />

      <ModalDocuments
        setSnack={setSnack}
        open={openModalDocuments}
        openModalExportFilesInline={openModalExportFilesInline}
        setOpenModalDocuments={setOpenModalDocuments}
        handleClose={() => setOpenModalDocuments(false)}
        fields={fields}
        idDisc={idDisc}
        setFields={setFields}
      />

      <ModalClassSchedule
        classId={id}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        setListSchedules
        open={openModalClassSchedule}
        listSchedules={listSchedules}
        listSelectListDisciplines={listSelectListDisciplines}
        setOpenModalClassSchedule={setOpenModalClassSchedule}
        handleClose={() => setOpenModalClassSchedule(false)}
      />

      <ModalAcademicResultsReport />
    </>
  )
}
export default Modals
