const DOCUMENT_TITLE_INDIVIDUAL_FORM = 'Ficha Individual'
const UNKNOWN_DESCRIBE = 'Não informado'

function evaluationDescribesByRegion(region, classTime, minScore) {
  if (region === 'SP') {
    return `Cada aula corresponde a ${classTime} minutos.\n\nÉ considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a ${minScore} pontos e frequência igual ou superior a 75% (setenta e cinco por cento). Até janeiro de 2023, é considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, a nota final igual ou superior a 5 (cinco) pontos em cada unidade curricular e frequência igual ou superior a 75% (setenta e cinco por cento). As notas precedidas de asterisco (*) estão abaixo da média. As notas precedidas de dois asteriscos (**) são proveniente de aproveitamento de estudos`
  }

  return `Cada aula corresponde a ${classTime} minutos.\n\nÉ considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a ${minScore} pontos e frequência igual ou superior a 75% (setenta e cinco por cento). As notas precedidas de asterisco (*) estão abaixo da média. As notas precedidas de dois asteriscos (**) são proveniente de aproveitamento de estudos`
}

export {
  DOCUMENT_TITLE_INDIVIDUAL_FORM,
  evaluationDescribesByRegion,
  UNKNOWN_DESCRIBE
}
