import generateDocDefinitionsBase from '../shared/BasePage'
import buildFooter from './Footer'
import buildClassInfo from './ClassInfo'
import { DOCUMENT_TITLE_NOTES_MAP } from './constants'
import buildTableNotes from './TableNotes'
import buildTableResume from './TableResume'
import buildTableRanking from './TableRanking'

export async function NotesMapPDF({
  classInfo,
  companyInfo,
  courseInfo,
  students,
  classSummary,
  subjects
}) {
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      footer: buildFooter
    }
  })

  const totalDisciplines = subjects.length
  const totalPagesForTableNotes = Math.ceil(totalDisciplines / 8)
  const tableNotes = []

  for (let i = 1; i <= totalPagesForTableNotes; i += 1) {
    const limit = 8
    const lastIndex = i * limit
    const firstIndex = lastIndex - limit
    const slicedSubjects = subjects.slice(firstIndex, lastIndex)

    const studentsWithSlicedGradeDetails = students.map(
      ({ student, gradeDetails }) => ({
        student,
        gradeDetails: gradeDetails.slice(firstIndex, lastIndex)
      })
    )

    tableNotes.push([
      buildClassInfo(courseInfo, classInfo),
      buildTableNotes(classInfo, studentsWithSlicedGradeDetails, slicedSubjects)
    ])
  }

  const studentsWithAverage = students.map(({ student, gradeDetails }) => {
    const totalScore = gradeDetails.reduce(
      (acc, { grade }) => acc + grade.finalGrade,
      0
    )
    const average = Math.round(totalScore / totalDisciplines)

    return {
      name: student.name,
      enrollment: student.enrollment,
      average
    }
  })

  studentsWithAverage.sort((a, b) => b.average - a.average)

  const studentsRanking = {
    bestStudents: studentsWithAverage.slice(0, 13),
    worstStudents: studentsWithAverage.slice(-7).reverse()
  }

  const pdfContent = [
    ...tableNotes,
    [buildClassInfo(courseInfo, classInfo), buildTableResume(classSummary)],
    [buildClassInfo(courseInfo, classInfo), buildTableRanking(studentsRanking)]
  ]

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_NOTES_MAP,
    mainContent: pdfContent
  })
}
