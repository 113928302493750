import theme from 'theme/designTokens'
import { clone } from 'services/helpers'

import generateDocDefinitionsBase from 'components/templates/PDF/shared/BasePage'
import buildSignature from 'components/templates/PDF/shared/BasePage/Signature'

import { UNITS } from 'services/constants'
import { DOCUMENT_TITLE } from '../constants'
import buildClassInfo from '../ClassInfo'
import buildTable from './Table'

const buildNoteDiaryNovotecContent = ({
  classInfo,
  activities,
  students,
  disciplineId
}) => {
  const locale =
    classInfo.unit === UNITS.TRAILS_OF_THE_FUTURE.name
      ? classInfo.poleCity
      : classInfo.unitCity
  const signature = buildSignature(classInfo.signatures, locale)
  return [
    [
      buildClassInfo(classInfo),
      ...buildTable({ classInfo, activities, students, disciplineId }),
      clone(signature)
    ]
  ]
}

export default async function noteDiaryNovotecPDF(notesData, disciplineId) {
  const signatureImage = notesData?.classInfo?.signatures[0]?.image
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage
        ? {
            images: {
              signature1: {
                url: notesData.classInfo.signatures[0].image,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }
            }
          }
        : {})
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE,
    mainContent: await buildNoteDiaryNovotecContent({
      classInfo: notesData.classInfo,
      activities: notesData.activities,
      students: notesData.students,
      disciplineId
    }),
    companyInfo: notesData.companyInfo
  })
}
