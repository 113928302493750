import {
  getCourseWorkload,
  isDateBefore,
  isDateEqual,
  parseDateStringToISODate
} from 'services/helpers'
import { UNITS } from 'services/constants'
import generateDocDefinitionsBase from '../shared/BasePage'
import buildSignature from '../shared/BasePage/Signature'
import { DOCUMENT_TITLE_INDIVIDUAL_FORM } from './constants'
import buildTableFooter from './TableFooter'
import buildPrimaryHeader from './PrimaryHeader'
import buildSecondaryHeader from './SecondaryHeader'
import buildTableInfo from './TableInfo'
import buildFooter from '../shared/BasePage/Footer'

export async function IndividualFormPDF({
  classInfo,
  companyInfo,
  courseInfo,
  students,
  subjects
}) {
  const parsedClassEndDate = parseDateStringToISODate(classInfo.classEndDate)
  const oldEvaluationDate = new Date(2023, 0, 31)
  const courseIsOldEvaluation = isDateBefore(
    oldEvaluationDate,
    parsedClassEndDate || isDateEqual(new Date(), parsedClassEndDate)
  )
  const isSP = classInfo.unitUf === 'SP'
  const minimumGrade =
    isSP && courseIsOldEvaluation ? 5 : classInfo.minimumGrade

  const totalNumberLessons = subjects.reduce(
    (acc, subject) => acc + subject.numberOfLessons,
    0
  )

  const doneWorkload = (totalNumberLessons * classInfo.classTime) / 60
  const courseWorkload = getCourseWorkload(
    classInfo.unit,
    courseInfo.theoreticalWorkload,
    courseInfo.internshipWorkload,
    courseInfo.practicalWorkload
  )
  const expectedClassDays = Math.ceil(courseWorkload / 6)
  const doneClassDays = Math.ceil(totalNumberLessons / 6)

  let totalPages = 0
  const studentsPagesMapper = {}

  const pdfContent = students.map(({ student, gradeDetails }) => {
    const initialTotalPages = totalPages + 1
    let totalPagesPerUser = 0
    let restOfPage = gradeDetails.length
    let isFirstBreak = true
    const firstBreak = 13
    const nextBreaks = 25

    for (let i = 1; restOfPage >= 0; i += 1) {
      if (isFirstBreak) {
        isFirstBreak = false
        restOfPage -= firstBreak
      } else {
        restOfPage -= nextBreaks
      }

      totalPages += 1
      totalPagesPerUser += 1
      studentsPagesMapper[totalPages] = { page: i }
    }

    for (let i = initialTotalPages; i <= totalPages; i += 1) {
      studentsPagesMapper[i].total = totalPagesPerUser
    }

    const content = [
      buildSecondaryHeader(student, courseInfo),
      buildTableInfo(minimumGrade, subjects, gradeDetails),
      buildTableFooter(
        classInfo,
        gradeDetails,
        courseWorkload,
        doneWorkload,
        expectedClassDays,
        doneClassDays
      ),
      buildSignature(classInfo.signatures, classInfo.unitCity, true)
    ]

    return content
  })

  const definitions = {
    definitionsToReplace: {
      pageOrientation: 'portrait',
      footer: (currentPage) => {
        const { page, total } = studentsPagesMapper[currentPage]
        return buildFooter(page, total)
      },
      images: {}
    }
  }

  classInfo.signatures?.forEach((signature, index) => {
    if (signature.image) {
      const signatureKey = `signature${index + 1}`

      definitions.definitionsToReplace.images[signatureKey] = {
        url: signature.image,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    }
  })

  const docDefinitionsBase = await generateDocDefinitionsBase(definitions)

  const companyInformations = companyInfo.slice(0, 2)
  let unitName = classInfo.unit

  if (UNITS.TRAILS_OF_THE_FUTURE.name === classInfo.unit) {
    let resolution = 'Autorização de funcionamento: '

    if (classInfo.unitResolution) {
      resolution += classInfo.unitResolution
    }

    if (classInfo.poleResolution) {
      resolution += `, ${classInfo.poleResolution}.`
    }

    if (classInfo.pole) {
      unitName += `/${classInfo.pole}`
    }

    companyInformations.push(resolution)
  }

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_INDIVIDUAL_FORM,
    mainContent: pdfContent,
    companyInfo: companyInformations,
    headerContent: () => buildPrimaryHeader(unitName)
  })
}
