import { formatNumberToScore } from 'services/helpers'
import theme from 'theme/designTokens'
import buildTable from '../../shared/Table'
import { utilsPDF } from '../../shared/utils'

const { addAsteristic } = utilsPDF

const textStyles = {
  alignment: 'center'
}

function formatPartialGrade(score) {
  const rest = score % 1
  const roundedScore = Math.ceil(score)

  if (rest > 0.5) {
    return roundedScore + 1
  }

  if (rest === 0) {
    return roundedScore
  }

  return roundedScore + 0.5
}

function formatScore(score, minimumGrade, item) {
  if ([null, undefined].includes(score)) return '-'

  const formattedScore = formatNumberToScore(score, item)

  if (item.recycling) return addAsteristic(formattedScore, 2)

  if (!score || score < minimumGrade) return addAsteristic(formattedScore)

  return formattedScore
}

function createNoteStyles(minimumGrade, score) {
  return {
    ...textStyles,
    color:
      ![null, undefined].includes(score) && score < minimumGrade
        ? theme.palette.red.light
        : null
  }
}

export default function buildTableInfo(minimumGrade, subjects, gradeDetails) {
  const tableSettings = {
    data: gradeDetails,
    columnStyle: {
      widths: [214.5, 35, 30, 30, 30, 45, 50, 40]
    },
    columns: [
      {
        label: 'Disciplina',
        rowLabelKey: 'discipline.name'
      },
      {
        label: 'Horas Aula',
        rowLabelKey: 'discipline.workload',
        columnStyle: textStyles,
        rowStyle: textStyles
      },
      {
        label: 'Nota Parcial',
        rowLabelKey: 'grade.partialGrade',
        rowLabelFormatter: (score, item) =>
          formatScore(formatPartialGrade(score), minimumGrade, item),
        columnStyle: textStyles,
        rowStyle: (score) => createNoteStyles(minimumGrade, score)
      },
      {
        label: 'Falta',
        rowLabelKey: 'frequency.absence',
        columnStyle: textStyles,
        rowStyle: textStyles
      },
      {
        label: 'Aulas',
        rowLabelKey: 'frequency.presence',
        rowLabelFormatter: (_, item, index) => subjects[index].numberOfLessons,
        columnStyle: textStyles,
        rowStyle: textStyles
      },
      {
        label: 'Recuperação',
        rowLabelKey: 'grade.recoveryGrade',
        rowLabelFormatter: (score, item) =>
          formatScore(score, minimumGrade, item),
        columnStyle: textStyles,
        rowStyle: (score) => createNoteStyles(minimumGrade, score)
      },
      {
        label: 'Reclassificação',
        rowLabelKey: 'grade.reclassificationGrade',
        rowLabelFormatter: (score, item) =>
          formatScore(score, minimumGrade, item),
        columnStyle: textStyles,
        rowStyle: (score) => createNoteStyles(minimumGrade, score)
      },
      {
        label: 'Nota Final',
        rowLabelKey: 'grade.finalGrade',
        rowLabelFormatter: (score, item) =>
          formatScore(score, minimumGrade, item),
        columnStyle: textStyles,
        rowStyle: (score) => createNoteStyles(minimumGrade, score)
      }
    ]
  }

  return buildTable(tableSettings)
}
