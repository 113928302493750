/* eslint-disable no-param-reassign */
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  createEmptyCells
} = utilsPDF

function addColSpan(colSpan, rowArray) {
  const emptyColumnsNumber = colSpan - 1
  const emptyCells = createEmptyCells(emptyColumnsNumber)
  rowArray.push(...emptyCells)
}

function addRowSpan(rowSpan, colSpan, gridArray, index) {
  const emptyRowsNumber = rowSpan - 1

  for (let i = 1; i <= emptyRowsNumber; i += 1) {
    const rowIndex = index + i

    if (!gridArray[rowIndex]) {
      gridArray[rowIndex] = []
    }

    gridArray[rowIndex][index] = createEmptyCells(1).at(0)

    if (colSpan) {
      addColSpan(colSpan, gridArray[rowIndex])
    }
  }
}

export default function buildGrid(elementsGrid) {
  const gridBody = []

  elementsGrid.forEach((elements, rowIndex) => {
    if (!gridBody[rowIndex]) {
      gridBody[rowIndex] = []
    }

    elements.forEach(({ colSpan, rowSpan, ...item }) => {
      gridBody[rowIndex].push({
        ...item,
        border: rowIndex > 0 ? noBorderOnTop : undefined,
        colSpan,
        rowSpan
      })

      if (colSpan) {
        addColSpan(colSpan, gridBody[rowIndex])
      }

      if (rowSpan) {
        addRowSpan(rowSpan, colSpan, gridBody, rowIndex)
      }
    })
  })

  return [
    {
      table: {
        body: gridBody,
        widths: '*'
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    }
  ]
}
