import React from 'react'
import { Typography } from '@mui/material'
import { Container } from './styles'

export default function NoOptionsText({ Icon, children }) {
  return (
    <Container>
      {Icon && <Icon />}
      <Typography component="span">{children}</Typography>
    </Container>
  )
}
