export function buildHeader() {
  return {
    text: 'Estado de Mato Grosso do Sul',
    bold: true,
    fontSize: 14,
    lineHeight: 1.2,
    alignment: 'center',
    margin: [0, 15, 0, 0]
  }
}
