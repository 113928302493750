import { formatNumberToScore } from 'services/helpers'
import theme from 'theme/designTokens'
import buildTable from '../../shared/Table'
import { utilsPDF } from '../../shared/utils'
import buildGrid from '../../shared/Grid'

const { addAsteristic } = utilsPDF

const textStyles = {
  alignment: 'center'
}

function formatScore(score, minimumGrade) {
  const formattedScore = score ? formatNumberToScore(score) : '0,00'

  if (!score || score < minimumGrade) return addAsteristic(formattedScore)

  return formattedScore
}

function addPercent(value) {
  return `${value}%`
}

function createNoteStyles(score, minimumGrade) {
  return {
    ...textStyles,
    color: !score || score < minimumGrade ? theme.palette.red.light : null
  }
}

export default function buildTableResume(classSummary) {
  return [
    buildTable({
      data: classSummary,
      columns: [
        [
          {
            label: 'Resumo da turma',
            columnStyle: {
              colSpan: 10,
              ...textStyles
            }
          }
        ],
        [
          {
            label: 'Disciplina',
            rowLabelKey: 'disciplineName',
            columnStyle: {
              colSpan: 2
            }
          },
          {
            label: 'Professor',
            rowLabelKey: 'professorName',
            columnStyle: {
              colSpan: 2
            }
          },
          {
            label: 'Qtd. Alunos',
            rowLabelKey: 'numberOfStudents',
            columnStyle: textStyles,
            rowStyle: textStyles
          },
          {
            label: 'A cima da média',
            rowLabelKey: 'approved',
            columnStyle: textStyles,
            rowStyle: textStyles
          },
          {
            label: '% A cima da média',
            rowLabelKey: 'approvedPercentage',
            rowLabelFormatter: addPercent,
            columnStyle: textStyles,
            rowStyle: textStyles
          },
          {
            label: 'A baixo da média',
            rowLabelKey: 'failed',
            columnStyle: textStyles,
            rowStyle: textStyles
          },
          {
            label: '% A baixo da média',
            rowLabelKey: 'failedPercentage',
            rowLabelFormatter: addPercent,
            columnStyle: textStyles,
            rowStyle: textStyles
          },
          {
            label: 'Média de notas',
            rowLabelKey: 'averageGrade',
            rowLabelFormatter: (score) => formatScore(score, 6),
            columnStyle: textStyles,
            rowStyle: (average) => createNoteStyles(average, 6)
          }
        ]
      ]
    }),
    buildGrid([
      [
        {
          text: 'Observações',
          style: 'tableHeaderCell'
        }
      ],
      [
        {
          text: '- Média de nota com asterisco (*) indica que a média da turma está abaixo da nota minima da etapa.'
        }
      ]
    ])
  ]
}
