import buildResponsibleTeacher from 'components/templates/PDF/shared/ResponsibleTeacher'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault } = utilsPDF

export function buildTableFooter({
  totalWorkloadCompleted,
  unit,
  numberOfCompletedLessons,
  responsibleTeachers
}) {
  return {
    table: {
      body: [
        ...buildResponsibleTeacher({
          responsibleTeachers,
          totalWorkloadCompleted,
          unit,
          numberOfCompletedLessons
        })
      ],
      widths: [200, 107, '*']
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 10,
      paddingLeft: () => 10,
      paddingRight: () => 10,
      paddingTop: () => 10
    }
  }
}
