import { utilsPDF } from 'components/templates/PDF/shared/utils'
import {
  calcCourseFrequencyPercent,
  isDateBefore,
  isDateEqual,
  parseDateStringToISODate
} from 'services/helpers'
import { evaluationDescribesByRegion } from '../constants'

const {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  createEmptyCells
} = utilsPDF

export default function buildTableFooter(
  classInfo,
  gradeDetails,
  courseWorkload,
  doneWorkload,
  expectedClassDays,
  doneClassDays
) {
  let totalAbscences = 0
  let result
  const currentDate = new Date()
  const parsedClassEndDate = parseDateStringToISODate(classInfo.classEndDate)
  const courseIsInProgress =
    isDateBefore(currentDate, parsedClassEndDate) ||
    isDateEqual(currentDate, parsedClassEndDate)

  const isApproved = gradeDetails.every(({ student, frequency }) => {
    totalAbscences += frequency.absence
    return student.approved
  })

  const frequency = calcCourseFrequencyPercent(
    totalAbscences,
    classInfo.classTime,
    courseWorkload
  )

  if (courseIsInProgress) {
    result = 'Cursando'
  } else if (isApproved) {
    result = 'Aprovado'
  } else {
    result = 'Reprovado'
  }

  return [
    {
      table: {
        body: [
          [
            {
              colSpan: 12,
              border: noBorderOnTop,
              text: 'Resumo',
              style: ['tableHeaderCell']
            },
            ...createEmptyCells(11)
          ],
          [
            {
              colSpan: 3,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Carga Horária',
                  bold: true
                },
                {
                  text: ['Previstas: ', courseWorkload]
                },
                {
                  text: ['Realizadas: ', doneWorkload]
                }
              ]
            },
            ...createEmptyCells(2),
            {
              colSpan: 3,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Dias letivos',
                  bold: true
                },
                {
                  text: ['Previstos: ', expectedClassDays]
                },
                {
                  text: ['Realizados: ', doneClassDays]
                }
              ]
            },
            ...createEmptyCells(2),
            {
              colSpan: 3,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Frequência',
                  bold: true
                },
                {
                  text: ['Faltas: ', totalAbscences]
                },
                {
                  text: ['% de Frequência: ', frequency]
                }
              ]
            },
            ...createEmptyCells(2),
            {
              colSpan: 3,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Resultado Final',
                  bold: true
                },
                {
                  text: result
                }
              ]
            },
            ...createEmptyCells(2)
          ],
          [
            {
              colSpan: 12,
              border: noBorderOnTop,
              text: 'Sistema De Avaliação',
              style: ['tableHeaderCell']
            },
            ...createEmptyCells(11)
          ],
          [
            {
              colSpan: 12,
              border: noBorderOnTop,
              text: evaluationDescribesByRegion(
                classInfo.unitUf,
                classInfo.classTime,
                classInfo.minimumGrade
              ),
              aligment: 'center'
            },
            ...createEmptyCells(11)
          ]
        ],
        widths: '*'
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    }
  ]
}
