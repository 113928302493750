import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2, Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import {
  convertToParams,
  formatDate,
  formatDateEnv,
  makeURLAccessLegacy,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { initialValues, optionTypeFile } from './constants'
import * as Styled from './style'
import { getExportFiles, openNotesMapPDF } from '../../../services'
import { useClassDetailsContext } from '../../../context'

const ModalExportFiles = ({
  handleClose,
  open,
  typeModal,
  setSnack,
  setLoadingOpen
}) => {
  const { classDetails } = useClassDetailsContext()

  function submitNotesMap(values) {
    if (typeModal === 'grades') {
      setLoadingOpen(true)

      const params = {
        school_class_id: classDetails.id,
        unit: classDetails.unit,
        course: classDetails.course,
        level: classDetails.level
      }

      if (values.startDate) params.start_date = formatDateEnv(values.startDate)
      if (values.endDate) params.end_date = formatDateEnv(values.endDate)

      return openNotesMapPDF(params, {
        openURlLegacy: () => {
          const legacyParams = {
            id_turma: classDetails.id,
            id_etapa: 4,
            nomeEtapa: 'Módulo'
          }

          if (values.startDate) {
            legacyParams.data1 = formatDate(values.startDate)
          }

          if (values.endDate) legacyParams.data2 = formatDate(values.endDate)

          makeURLAccessLegacy(
            `acesso/mapa_notas.php?${convertToParams(legacyParams)}`
          )
        },
        onFinish: () => {
          setLoadingOpen(false)
          handleClose()
        },
        onError: ({ error, status }) =>
          updateErrorMessage({
            setSnack,
            error,
            status,
            feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToNotesMap
          })
      })
    }

    return getExportFiles({
      setSnack,
      setLoadingOpen,
      values,
      id: classDetails.id,
      handleClose,
      typeModal
    })
  }

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(...params) => submitNotesMap(...params)}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    {typeModal === 'grades'
                      ? 'Mapa de Notas'
                      : 'Ata de Resultados Finais'}
                  </Styled.Title>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    Escolha o período para as
                    {typeModal === 'grades'
                      ? ' notas '
                      : ' atas de resultados '}
                    que você deseja gerar:
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <DatePicker
                    fullWidth
                    id="startDate"
                    label="De (opcional)"
                    value={values?.startDate || null}
                    onChange={(newValue) => {
                      setFieldValue('startDate', newValue)
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <DatePicker
                    fullWidth
                    id="endDate"
                    label="Até (opcional)"
                    value={values?.endDate || null}
                    onChange={(newValue) => {
                      setFieldValue('endDate', newValue)
                    }}
                  />
                </Grid>

                {typeModal !== 'grades' && (
                  <Grid item xs={3}>
                    <Select
                      id="typeFile"
                      label="Formato"
                      value={values?.typeFile || ''}
                      optionsList={optionTypeFile}
                      onChange={(e) =>
                        setFieldValue('typeFile', e.target.value)
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Confirmar"
                  classNameConfirm={
                    typeModal === 'grades'
                      ? 'academico_btn_baixa_Notas'
                      : 'academico_btn_baixa_ataResultados'
                  }
                  size="medium"
                  onClickClose={handleClose}
                />
              </Styled.Box>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalExportFiles }
