import { NOVOTEC_UNIT } from 'services/constants/units'
import theme from 'theme/designTokens'

const getLabel = (unit) =>
  unit === NOVOTEC_UNIT ? 'Aulas realizadas' : 'Carga horária total cumprida'

const HEADER_STYLE = {
  bold: true,
  fillColor: theme.palette.background.tableHeaderPDF
}

const getCompletedLessonsText = (
  teacher,
  unit,
  professorCode,
  totalWorkloadCompleted
) => {
  if (unit === NOVOTEC_UNIT) {
    return professorCode?.length > 0
      ? `${teacher.completedLessons}`
      : `${teacher.completedLessons} aulas`
  }
  return `${totalWorkloadCompleted} horas`
}

const buildResponsibleTeacher = ({
  responsibleTeachers,
  totalWorkloadCompleted,
  unit
}) => {
  const headerRow = [
    { text: 'Docente responsável', ...HEADER_STYLE },
    { text: getLabel(unit), ...HEADER_STYLE },
    { text: 'Assinatura', ...HEADER_STYLE }
  ]

  const { professorCode } = responsibleTeachers[0]

  const dataRows = responsibleTeachers?.reduce((acc, teacher) => {
    if (teacher.completedLessons > 0) {
      acc.push([
        { text: teacher.name?.toUpperCase() },
        {
          text: getCompletedLessonsText(
            teacher,
            unit,
            professorCode,
            totalWorkloadCompleted
          )
        },
        { text: '' }
      ])
    }
    return acc
  }, [])

  return [headerRow, ...(dataRows ?? [])]
}

export default buildResponsibleTeacher
