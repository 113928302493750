import { lazy, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { Button, TextNotFound } from 'components/atoms'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { updateErrorMessage, paginationFilterDefault } from 'services/helpers'
import { getStudentsDeclaration } from 'services/api/admin'
import { PaginationAdvanced } from 'components/molecules'

import {
  MESSAGE,
  breadcrumbsItems,
  countDefault,
  defaultItemsPerPage
} from './constants'
import { handleNewCourse } from './services'
import * as Styled from './style'

const Table = lazy(() => import('./components/Table'))

const StudentDeclaration = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { name, register } = params
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [declarationList, setDeclarationList] = useState([])

  const { page, totalPage, items, setPage } = usePaginationBasic(
    declarationList,
    filter,
    count
  )

  const handleSetPage = (_, currentPage) => {
    const pageNumber = parseInt(currentPage, 10)
    if (!Number.isNaN(pageNumber)) {
      setPage(pageNumber)
    }
  }

  const onHandleConfirm = () => {
    setOpen(false)
    navigate(-1)
  }

  const handleDeclaration = async (Page) => {
    setSnack('', '')
    setLoading(true)

    const { data, error, status } = await getStudentsDeclaration(
      register,
      filter,
      Page
    )

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
      })
      return
    }
    setCount(data.count)
    setDeclarationList(data.data)
  }

  let messageInformative = ''

  if (declarationList.length === 0) {
    messageInformative = MESSAGE.NO_SEARCH
  }

  useEffect(() => {
    if (page > totalPage) {
      setPage(totalPage)
    }
    handleDeclaration(page)
  }, [setFilter, totalPage, declarationList.length])

  return (
    <TemplateDefaultHeaderByContent
      classNameHeader="goBack"
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems({ name })}
      onClickButton={() => setOpen(true)}
      open={open}
      handleCloseModal={() => setOpen(false)}
      handleConfirmModal={() => onHandleConfirm()}
      setLoading={setLoading}
      content={
        <>
          {messageInformative && (
            <TextNotFound icon={<ListAlt />} text={messageInformative} />
          )}
          <Styled.ButtonGroup
            messageExists={declarationList.length > 0}
            messageCenter={messageInformative === MESSAGE.NO_SEARCH}
          >
            <Button
              size="large"
              value="nova declaração"
              type="submit"
              onClick={() => handleNewCourse({ navigate, register, name })}
            />
          </Styled.ButtonGroup>
          {declarationList.length > 0 && (
            <Table rows={items} setSnack={setSnack} setLoading={setLoading} />
          )}
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilterDefault}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={(event, newPage) => {
              handleSetPage(event, newPage)
              handleDeclaration(newPage)
            }}
            handleChange={(e) => {
              const newFilter = e.target.value
              setFilter(newFilter)
              handleDeclaration(page)
            }}
          />
        </>
      }
    />
  )
}

export default StudentDeclaration
