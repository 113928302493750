import theme from 'theme/designTokens'
import { clone, formatDate } from 'services/helpers'
import { UNITS } from 'services/constants'
import generateDocDefinitionsBase from '../shared/BasePage'
import { utilsPDF } from '../shared/utils'
import buildClassInfo from './ClassInfo'
import { DOCUMENT_TITLE, WITHOUT_SIGNATURE_IMAGE } from './constants'
import buildTable from './Table/Body'

import buildSignatureWithoutImage from '../shared/BasePage/SignatureWithoutImage'
import { buildTableFooter } from './Table/Footer'
import { buildTableHeader } from './Table/Header'

const { chunkArray } = utilsPDF

const buildContentDiaryContent = async (classInfo, lessons, disciplineInfo) => {
  const { disciplineStartDate, disciplineEndDate } = disciplineInfo
  const formattedStartDate = formatDate(disciplineStartDate)
  const formattedEndDate = formatDate(disciplineEndDate)
  const period = `${formattedStartDate} até ${formattedEndDate}`
  const { disciplineName, disciplineWorkload } = disciplineInfo

  const updatedClassInfo = { ...classInfo }

  if (
    !Object.prototype.hasOwnProperty.call(
      updatedClassInfo,
      'numberOfCompletedLessons'
    )
  ) {
    updatedClassInfo.numberOfCompletedLessons = lessons.length
  }

  const teachers = lessons.reduce((acc, lesson) => {
    const professorCode = lesson.lessonProfessorCode

    const existingTeacher = acc.find((p) => p.professorCode === professorCode)

    if (existingTeacher) {
      existingTeacher.completedLessons += 1
    } else {
      acc.push({
        professorCode,
        completedLessons: 1,
        name: lesson.lessonProfessorName
      })
    }

    return acc
  }, [])

  if (
    !Object.prototype.hasOwnProperty.call(
      updatedClassInfo,
      'responsibleTeachers'
    )
  ) {
    updatedClassInfo.responsibleTeachers = teachers
  }

  if (
    !Object.prototype.hasOwnProperty.call(
      updatedClassInfo,
      'totalWorkloadCompleted'
    )
  ) {
    updatedClassInfo.totalWorkloadCompleted =
      updatedClassInfo.numberOfCompletedLessons
  }

  const signatureData = [
    {
      identifier: classInfo.directorUnitIdentifier,
      image: classInfo.directorUnitSignatureImage,
      name: classInfo.directorUnitName,
      role: classInfo.directorUnitRole
    }
  ]

  const locale =
    classInfo.unit === UNITS.TRAILS_OF_THE_FUTURE.name
      ? classInfo?.poleCity
      : classInfo?.unitCity
  const lessonsChunk = chunkArray(lessons, lessons.length)

  const signature = buildSignatureWithoutImage(
    signatureData,
    locale,
    WITHOUT_SIGNATURE_IMAGE
  )

  const content = lessonsChunk.map((chunk, index) => [
    buildClassInfo(classInfo, period, disciplineName, disciplineWorkload),
    buildTableHeader(),
    buildTable({ lessons: chunk })
  ])

  const lastChunkOfContent = content[content.length - 1]
  lastChunkOfContent.push(buildTableFooter(updatedClassInfo), clone(signature))

  return content
}

export async function ContentDiaryGeneralPDF(data) {
  const signatureImage = data.classInfo?.directorUnitSignatureImage

  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      pageOrientation: 'portrait',
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage
        ? {
            images: {
              signature1: {
                url: signatureImage,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }
            }
          }
        : {})
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE,
    mainContent: await buildContentDiaryContent(
      data.classInfo,
      data.lessons,
      data.disciplineInfo
    ),
    companyInfo: data?.companyInfo
  })
}
