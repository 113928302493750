import theme from 'theme/designTokens'
import { roundScore } from 'services/helpers'
import generateDocDefinitionsBase from '../../shared/BasePage'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_MS } from './constants'
import { buildHeader } from './Header'
import { buildRecordInfo } from './RecordInfo'
import { buildTable } from './Table'
import { buildProfessionalProfile } from './ProfessionalProfile'

function handleSubjects({ subjects }) {
  const moduleInRoman = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV'
  }
  const subjetcsByModule = subjects.reduce((acc, currentSubject) => {
    const currentModule = acc[currentSubject.module]

    if (currentModule) {
      currentModule.beginAt =
        new Date(currentSubject.beginAt).getTime() >
        new Date(currentModule.beginAt).getTime()
          ? currentModule.beginAt
          : currentSubject.beginAt

      currentModule.endAt =
        new Date(currentSubject.endAt).getTime() <
        new Date(currentModule.endAt).getTime()
          ? currentModule.endAt
          : currentSubject.endAt

      currentModule.subjectId.push(currentSubject.subjectId)
      currentModule.subjects.push(currentSubject)

      currentModule.workload += currentSubject.workload
    } else {
      acc[currentSubject.module] = {
        beginAt: currentSubject.beginAt,
        endAt: currentSubject.endAt,
        module: currentSubject.module,
        name: `Unidade Curricular ${moduleInRoman[currentSubject.module]}`,
        subjectId: [currentSubject.subjectId],
        workload: currentSubject.workload,
        subjects: [currentSubject]
      }
    }

    return acc
  }, {})
  const subjectList = Object.values(subjetcsByModule)
  const subjectsMap = new Map(subjectList.map((el) => [el.module, el]))

  return {
    subjectsMap,
    subjectList
  }
}

function getGradeDetails({ gradeDetails, subjectsMap, classInfo }) {
  const gradeDetailsByModules = gradeDetails.reduce(
    (acc, currentGradeDetail) => {
      const { frequency, grade, student } = currentGradeDetail
      const moduleNumber = currentGradeDetail.discipline.module
      const currentModule = acc[moduleNumber]

      if (currentModule) {
        currentModule.student = {
          ...currentModule.student,
          active: currentModule.student.active ? student.active : false,
          occurrence_type_name:
            currentModule.student.occurrence_type_name ??
            student.occurrence_type_name,
          has_academic_performance:
            currentModule.student.has_academic_performance ??
            student.has_academic_performance,
          academic_performance_grade:
            currentModule.student.academic_performance_grade ??
            student.academic_performance_grade,
          approved: currentModule.student.approved ? student.approved : false
        }

        currentModule.frequency = {
          absence: currentModule.frequency.absence + frequency.absence,
          absenceInPercentage:
            currentModule.frequency.absenceInPercentage +
            frequency.absenceInPercentage,
          approvedByFrequency: currentModule.frequency.approvedByFrequency
            ? frequency.approvedByFrequency
            : false,
          justifiedAbsence:
            currentModule.frequency.justifiedAbsence +
            frequency.justifiedAbsence,
          maximumAbsence:
            currentModule.frequency.maximumAbsence + frequency.maximumAbsence,
          presence: currentModule.frequency.presence + frequency.presence
        }

        currentModule.grade = {
          assessmentGrade:
            currentModule.grade.assessmentGrade + grade.assessmentGrade,
          activitiesGrade:
            currentModule.grade.activitiesGrade + grade.activitiesGrade,
          partialGrade: currentModule.grade.partialGrade + grade.partialGrade,
          recoveryGrade:
            currentModule.grade.recoveryGrade + grade.recoveryGrade,
          finalGrade: currentModule.grade.finalGrade + grade.finalGrade,
          approvedByGrade: false
        }
      } else {
        acc[moduleNumber] = currentGradeDetail
        acc[moduleNumber].discipline = subjectsMap.get(moduleNumber)
      }

      return acc
    },
    {}
  )

  const gradeDetailsList = Object.values(gradeDetailsByModules)

  gradeDetailsList.forEach((el) => {
    const subject = subjectsMap.get(el.discipline.module)
    const amountSubjectsByModule = subject?.subjectId.length
    const finalGrade = Number(
      roundScore(el.grade.finalGrade / amountSubjectsByModule)
    )
    // eslint-disable-next-line no-param-reassign
    el.student.approved = finalGrade >= classInfo.minimumGrade
    // eslint-disable-next-line no-param-reassign
    el.grade.finalGrade = finalGrade
    // eslint-disable-next-line no-param-reassign
    el.frequency.absenceInPercentage /= amountSubjectsByModule
  })

  return gradeDetailsList
}

export function buildMainContent({
  classInfo,
  student,
  gradeDetails,
  courseInfo,
  subjects,
  withSignature
}) {
  const { subjectsMap, subjectList } = handleSubjects({ subjects })

  return [
    [
      buildRecordInfo({ classInfo, student }),
      ...buildTable({
        gradeDetails: getGradeDetails({ gradeDetails, subjectsMap, classInfo }),
        classInfo,
        subjects: subjectList,
        withSignature
      }),
      buildProfessionalProfile({ courseInfo })
    ]
  ]
}

export async function AcademicRecordPDFtypeMS({
  academicRecordData,
  withSignature
}) {
  const {
    classInfo,
    companyInfo: companyInfoList,
    students,
    studentAcademicReportInfo,
    courseInfo,
    subjects
  } = academicRecordData
  const signatureImage1 = classInfo?.signatures[0]?.image
  const signatureImage2 = classInfo?.signatures[1]?.image
  const [{ student, gradeDetails }] = students

  const studentInfo = {
    ...student,
    ...studentAcademicReportInfo
  }
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      pageOrientation: 'portrait',
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: signatureImage1,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: signatureImage2,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_ACADEMIC_RECORD_MS,
    mainContent: buildMainContent({
      classInfo,
      student: studentInfo,
      gradeDetails,
      courseInfo,
      subjects,
      withSignature
    }),
    // companyInfo: buildCompanyInfo({ companyInfoList }),
    headerContent: buildHeader()
  })
}
