import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { useFormik } from 'formik'
import { DatePicker } from 'components/atoms'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { UNITS } from 'services/constants'
import {
  convertToParams,
  formatDate,
  makeURLAccessLegacy
} from 'services/helpers'
import { typesReports } from './constants'
import { getSchema } from './schema'
import { useClassDetailsContext } from '../../../context'
import { openResultReportPDF, openPartialReportPDF } from '../../../services'
import { FormReportPartialLikeNovotec } from '../../FormReportPartialLikeNovotec'

export function ModalAcademicResultsReport() {
  const {
    openModalResultsReport,
    handleCloseModalResultsReport,
    classId,
    unitId,
    classDetails
  } = useClassDetailsContext()
  const { setLoading } = useLoading()
  const isLikeNovotec = [UNITS.NOVOTEC.name, UNITS.MS.name].includes(
    classDetails?.unit
  )

  const formik = useFormik({
    initialValues: { typeReport: null, startDate: null, endDate: null },
    onSubmit: async (values) => {
      setLoading(true)
      const isPartialReport = values.typeReport === typesReports.partialReport

      if (isPartialReport && isLikeNovotec) {
        return openPartialReportPDF({
          classId,
          endDate: values.endDate,
          startDate: values.startDate,
          onError: () => setLoading(false),
          onFinish: () => setLoading(false)
        })
      }

      return openResultReportPDF({
        classId,
        isPartialReport,
        endDate: values.endDate,
        startDate: values.startDate,
        onError: () => setLoading(false),
        onFinish: () => setLoading(false),
        openURlLegacy: () =>
          makeURLAccessLegacy(
            `acesso/ata_resultados_TP_15.php?${convertToParams({
              id_turma: classId,
              ...(values.endDate && { data2: formatDate(values?.endDate) }),
              ...(values.startDate && { data1: formatDate(values?.startDate) }),
              ...(values?.typeFile === 'xls' && { tipoArquivo: 'excel' })
            })}`,
            true
          )
      })
    },
    validationSchema: getSchema()
  })

  const isPartialReport =
    formik.values.typeReport === typesReports.partialReport
  return (
    <Dialog
      PaperProps={{ component: 'form', onSubmit: formik.handleSubmit }}
      sx={{ '& .MuiPaper-root': { width: { md: '600px' } } }}
      open={openModalResultsReport}
    >
      <DialogTitle fontWeight={600} fontSize="32px">
        Ata de Resultados
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs={12}>
            <FormControl error={formik.errors.typeReport}>
              <Typography>
                Selecione a ata que deseja gerar:
                <Box
                  component="span"
                  sx={{ color: (t) => t.palette.error.main }}
                >
                  *
                </Box>
              </Typography>
              <RadioGroup
                aria-labelledby="Selecione a ata que deseja gerar"
                defaultValue="female"
                name="typeReport"
                id="typeReport"
                value={formik.values.typeReport}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value={typesReports.finalReport}
                  control={<Radio />}
                  label="Ata de Resultados Finais"
                />
                <FormControlLabel
                  value={typesReports.partialReport}
                  control={<Radio />}
                  label="Ata Parcial"
                />
              </RadioGroup>
              {formik.errors.typeReport && (
                <FormHelperText>{formik.errors.typeReport}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {isPartialReport && (
            <>
              {isLikeNovotec ? (
                <FormReportPartialLikeNovotec
                  handleClose={() => {
                    formik.resetForm()
                    handleCloseModalResultsReport()
                  }}
                />
              ) : (
                <Grid xs={12} container spacing={1}>
                  <Grid xs={12} mt={3}>
                    <Typography>
                      Escolha o período para a Ata Parcial:
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <DatePicker
                      fullWidth
                      id="startDate"
                      name="startDate"
                      label="De"
                      value={formik.values?.startDate || null}
                      onChange={(newValue) => {
                        formik.setFieldValue('startDate', newValue)
                      }}
                      error={formik.errors?.startDate}
                      helperText={formik.errors?.startDate}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <DatePicker
                      fullWidth
                      id="endDate"
                      name="endDate"
                      label="Até"
                      value={formik.values?.endDate || null}
                      onChange={(newValue) => {
                        formik.setFieldValue('endDate', newValue)
                      }}
                      error={formik.errors?.endDate}
                      helperText={formik.errors?.endDate}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      {!(isPartialReport && isLikeNovotec) && (
        <DialogActions sx={{ padding: '0 24px 20px' }}>
          <ButtonFooterV2
            labelClose="Cancelar"
            labelConfirm="Confirmar"
            disabledConfirm={Object.keys(formik.errors).length}
            onClickClose={() => {
              formik.resetForm()
              handleCloseModalResultsReport()
            }}
          />
        </DialogActions>
      )}
    </Dialog>
  )
}
