import theme from 'theme/designTokens'

const { capitalizeWords } = require('services/helpers')

const withTwoSignatures = (signatures, withSignatureImage) => [
  [
    {},
    withSignatureImage && signatures[1].image
      ? { image: 'signature2', fit: [100, 50], alignment: 'center' }
      : {},
    {},
    withSignatureImage && signatures[0].image
      ? { image: 'signature1', fit: [100, 50], alignment: 'center' }
      : {},
    {}
  ],
  [
    {},
    {
      stack: [
        {
          text: signatures[1].name,
          bold: true
        },
        {
          text: signatures[1].role
        },
        {
          text: signatures[1].identifier
        }
      ],
      alignment: 'center',
      border: [false, true, false, false]
    },
    {},
    {
      stack: [
        {
          text: signatures[0].name,
          bold: true
        },
        {
          text: signatures[0].role
        },
        {
          text: signatures[0].identifier
        }
      ],
      alignment: 'center',
      border: [false, true, false, false]
    },
    {}
  ]
]

const withOneSignature = (
  signatures = [],
  withSignatureImage,
  withoutImage
) => [
  [
    { text: '', border: [true, false, false, false] },
    { text: '', border: [false, false, false, false] },
    withSignatureImage && signatures[0].image && !withoutImage
      ? {
          image: 'signature1',
          width: 120,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        }
      : {
          text: '',
          margin: [0, 50, 0, 3],
          border: [false, false, false, true]
        },
    { text: '', border: [false, false, false, false] },
    { text: '', border: [false, false, true, false] }
  ],
  [
    { text: '', border: [true, false, false, true] },
    { text: '', border: [false, false, false, true] },
    {
      stack: [
        {
          text: signatures[0].name,
          bold: true,
          margin: [0, 0, 0, 5],
          border: [false, true, true, true]
        },
        {
          text: signatures[0].role,
          margin: [0, 0, 0, 5],
          border: [false, false, false, true]
        },
        {
          text: signatures[0].identifier,
          margin: [0, 0, 0, 5],
          border: [false, false, false, true]
        }
      ],
      alignment: 'center',
      border: [false, true, false, true]
    },
    { text: '', border: [false, false, false, true] },
    { text: '', border: [false, false, true, true] }
  ]
]

const handleSignatures = (
  signatures = [],
  withSignatureImage,
  withoutImage
) => {
  const { length } = signatures

  const lengthSignaturesMap = {
    1: () => withOneSignature(signatures, withSignatureImage, withoutImage),
    2: () => withTwoSignatures(signatures, withSignatureImage)
  }

  return lengthSignaturesMap[length]?.() ?? []
}

const buildSignatureWithoutImage = (
  signatures = [],
  locale = 'São Paulo',
  withoutImage,
  withSignatureImage = true,
  signatureMarginTop = 0
) => {
  if (!signatures?.length) return []
  const signaturesData = handleSignatures(
    signatures,
    withSignatureImage,
    withoutImage
  )
  return [
    {
      id: 'signature',
      table: {
        body: [
          [
            {
              text: `${capitalizeWords(locale)}, ${new Date().toLocaleString(
                'pt-BR',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }
              )}`,
              colSpan: 5,
              alignment: 'center'
            },
            {},
            {},
            {},
            {}
          ],
          ...signaturesData
        ],
        widths: ['*', '*', '*', '*', '*'],
        heights: ['auto', withSignatureImage ? 'auto' : 10, 'auto'],
        border: [true, true, true, true]
      },
      layout: {
        defaultBorder: true,

        vLineWidth: () => 0.1,
        hLineWidth: (i, node) =>
          i === 0 || i === 2 || i === node.table.body.length ? 0.1 : 0,

        vLineColor: () => theme.palette.pdf.borderMedium,
        hLineColor: () => theme.palette.pdf.borderMedium,
        paddingTop: (i, node) => (i === 0 ? 40 : 5),
        paddingBottom: (i, node) => (i === node.table.body.length - 1 ? 100 : 0)
      },
      marginTop: signatureMarginTop
    }
  ]
}

export default buildSignatureWithoutImage
